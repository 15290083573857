import React, { useState, Suspense, useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme, darkTheme } from "./themes/theme";
import Snackbars from "./globalModule/Snackbars";
import { useDispatch, useSelector } from "react-redux";
import Loaders from "./globalModule/Loaders";
import { currentSnackbar, setSnackbar } from "./redux/reducers/snackbar";
import { changeLoader, currentLoader } from "./redux/reducers/loader";
// import './scss/style.scss';

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const LoginPage = React.lazy(() => import("./pages/auth/LoginPage"));

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const snackbarObj = useSelector(currentSnackbar);
  const loading = useSelector(currentLoader);
  const dispatch = useDispatch();

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (snackbarObj?.snackbarOpen) {
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            snackbarOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbarObj?.snackbarOpen]);

  return (
    <>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <HashRouter>
          <Suspense fallback={<></>}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<LoginPage />} />
              <Route path="*" name="Home" element={<DefaultLayout toggleDarkMode={toggleDarkMode} />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </ThemeProvider>

      <Snackbars
        snackBarMessage={snackbarObj?.snackbarMessage}
        snackbarOpen={snackbarObj?.snackbarOpen}
        snackBarState={snackbarObj?.snackbarState}
      />

      {loading ? <Loaders /> : null}
    </>
  );
}

export default App;
