import { createSlice } from "@reduxjs/toolkit";

export const addColumn = createSlice({
  name: "addColumn",
  initialState: {
    value: [
      {
        word: "",
      },
      {
        word: "",
      },
      {
        word: "",
      },
      {
        word: "",
      },
    ],
  },
  reducers: {
    changeAddColumn: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeAddColumn } = addColumn.actions;
export const currentAddColumn = (state) => state.addColumn.value;
export default addColumn.reducer;
