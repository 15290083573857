import { createSlice } from "@reduxjs/toolkit";
import { STORAGE } from "../../Enum";
import addDeleteGetLocalStorage from "../../globalModule/addDeleteGetLocalStorage";

const getData = () => {
  let data = addDeleteGetLocalStorage(STORAGE.LOCAL_DATA, {}, "get", "single");
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};
export const getLocalData = createSlice({
  name: "getLocalData",
  initialState: {
    value: getData(),
  },
  reducers: {
    changeLocalValue: (state, action) => {
      state.value = getData();
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLocalValue } = getLocalData.actions;
export const currentLocalValue = (state) => state.getLocalData.value;
export default getLocalData.reducer;
