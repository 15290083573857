import { BaseUrl } from "./BaseUrl";

export const API = {
  GET_INTEL: `intels/get-intel`,
  IMPORT_CSV: `intels/import-csv`,
  SEARCH: `intels/search-record`,
  EDIT: `intels/edit-record`,
  UPDATE_PARTICULAR_RECORD: `intels/update-particular-record`,
  ADD_PARTICULAR_RECORD: `intels/add-particular-record`,
  LOGIN: `intels/login`,
  DELETE_CELL: `intels/delete-cell`,
  DELETE_COLUMN: `intels/delete-column`,
  DELETE_SELECTED_RECORD: `intels/delete-selected-record`,
  FORGOT_PASSWORD: `intels/forgot-password`,
  VERIFY_FORGOT_PASSWORD_OTP: `intels/verify-forgot-password-otp`,
  CHAT_GPT_DATA: `intels/get-chat-gpt-data`,
  EXPORT_STRING: "intels/export-string-from-table",
};

export const STORAGE = {
  LOCAL_DATA: "kdjcnhJDNCd",
  TOKEN: "alkyeuidj3al",
};

export const REQUEST = {
  API_KEY: "my@racoons.com",
};
