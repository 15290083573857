import { useState, useEffect,forwardRef } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbars = ({ snackbarOpen, snackBarState, snackBarMessage }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (snackbarOpen) {
      setOpen(true);
    }
  }, [snackbarOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarState} sx={{ width: "100%" }}>
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Snackbars;
